<template>
  <form-field :name="field.name">
    <v-select
      v-model="field.value"
      :hint="hint"
      :items="donor"
      :menu-props="{maxHeight:'250'}"
      v-bind="field.props"
      dense
      box
      label="Select donor`s msisdn"
      validate-on-blur
    />
  </form-field>
</template>

<script>
import formField from '@/mixins/form-field'
import { createGetParams } from '@/rest'

export default {
  mixins: [formField],
  props: {
    value: {
      type: String,
      required: false,
      default: null,
    },
  },
  data () {
    return {
      donor: [],
      hint: 'Select Donor`s Msisdn',
    }
  },
  mounted: function () {
    this.getStates()
  },
  methods: {
    getStates: function () {
      this.$rest.get('getDonors.php', createGetParams({}))
        .then(function (response) {
          for (const item of response.data) {
            this.donor.push(item.donor)
          }
        }.bind(this)).catch(() => {
          // ignore
        })
    },
  },
}
</script>
